import React, { useState, useEffect } from "react"

const ProductsList = ({ products }) => {
  const [isReady, setIsReady] = useState(false)

  const [selectedProduct, setSelectedProduct] = useState(null) // Tracks the selected product
  const [isPopupVisible, setIsPopupVisible] = useState(false) // Tracks popup visibility

  const handleProductClick = (product) => {
    setSelectedProduct(product) // Set the clicked product as the selected product
    setIsPopupVisible(true) // Show the popup
  }

  const closePopup = () => {
    setIsPopupVisible(false) // Hide the popup
    setSelectedProduct(null) // Clear the selected product
  }

  useEffect(() => {
    // Preload all images
    const imagePromises = products.map((product) => {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = product.images[0]

        img.onload = resolve
        img.onerror = reject
      })
    })

    Promise.all(imagePromises)
      .then(() => setIsReady(true))
      .catch((err) => {
        console.error("Some images failed to load", err)
        setIsReady(true) // Render page even if some images fail
      })
  }, [products])

  if (!isReady) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-8 h-8 border-4 border-yellow-500 rounded-full loader animate-spin border-t-transparent"></div>
        <span className="ml-2 text-yellow-500">Loading...</span>
      </div>
    )
  }

  return (
    <div className="w-full realtive">
      {isPopupVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-11/12 max-w-md overflow-hidden  bg-[#FFF7BC] rounded-2xl shadow-lg">
            {/* Close Button */}
            <button
              onClick={closePopup}
              className="absolute text-gray-600 top-4 right-4 hover:text-gray-800"
            >
              &times;
            </button>

            {/* Product Details */}
            <div className="flex flex-col items-center p-6">
              <h2 className="mb-4 text-lg font-bold uppercase">
                {selectedProduct.title}
              </h2>
              <img
                src={selectedProduct.images[0]}
                alt={selectedProduct.title}
                className="object-contain w-full h-48 mb-4"
              />
              {selectedProduct.description ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedProduct.description,
                  }}
                ></div>
              ) : (
                <p>No description available.</p>
              )}
            </div>
            <div className="flex flex-col w-full p-6 bg-white">
              <a href={`https://ananas.delliveroo.com/?p=${localStorage.getItem('pixel')}&promo=${localStorage.getItem('promo')}`} className="mb-4" >
                <img src="/Frame 39.png" alt="" />
              </a>
              <a href={`https://tallabatt.com/?p=${localStorage.getItem('pixel')}&promo=${localStorage.getItem('promo')}`}>
                <img src="/Frame 40.png" alt="" />

              </a>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-2 gap-2 bg-[#FFF7BC] px-3 py-4">
        {products.map((product, index) => (
          <div
            key={product.id || index}
            className="flex flex-col items-center rounded-sm shadow cursor-pointer"
            onClick={() => handleProductClick(product)} // Trigger popup on click
          >
            <span className="w-full h-12 font-medium text-center uppercase">
              {product.title}
            </span>
            <div className="flex items-center justify-center w-full h-48 overflow-hidden">
              <img
                src={product.images[0]}
                alt={product.title}
                className="object-contain w-full h-full"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductsList
