import React from 'react'
import { usePromo } from './providers/Init'

const Promo = () => {
    const promo = usePromo()
  return (
    <div className="relative flex items-center w-full">
    <img src="/Frame 41.png" alt="" />
    <div className="absolute flex flex-col items-end right-4">
      <span className="text-2xl font-black text-white text-end">Flash Sale: <span className='text-[#e2fc50] font-black text-4xl'>{promo}%</span></span>
      <span className="text-xl font-black text-white text-end">Off Everything!</span>
    </div>
    </div>
  )
}

export default Promo