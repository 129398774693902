import React, { createContext, useContext, useState, useEffect } from 'react';

import ReactPixel from 'react-facebook-pixel';
import useHistoryParams from './useHistoryParam';


const PromoContext = createContext();

export const usePromo = () => useContext(PromoContext);

export const PromoProvider = ({ children }) => {
  const { params, updateParams } = useHistoryParams();
  const [promo, setPromo] = useState(0.5);

  useEffect(() => {
    const pixelParam = params.get('p');
    const promoAmount = params.get('promo'); // Retrieve 'promo' parameter

    if (pixelParam) {
      localStorage.setItem('pixel', pixelParam);
    }


    if (promoAmount) {
      localStorage.setItem('promo', promoAmount)
      setPromo(promoAmount)
    }


    // Remove params from URL if they exist
    if (pixelParam ) {
      updateParams({ p: null, promo: null});
    }
  }, [params, updateParams]);

  return (
    <PromoContext.Provider value={promo}>
      {children}
    </PromoContext.Provider>
  );
};
